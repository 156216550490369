'use strict';

import ClassLogger from 'ClassLogger';

export default class StickyHeader {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'StickyHeader';
    }

    constructor () {
        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging
        this.init();
    }

    /**
     * Initialize
     *
     * @returns {this}
     */
    init () {
        this.mainHeader = document.querySelector('.l-page-header');
        this.footer = document.querySelector('.l-page-footer');
        if (!this.mainHeader) {
            this.logger.log('No Header found');
            return;
        }

        // We only need this effect for screens wider than 800px
        if (window.matchMedia('(min-width: 1020px)').matches) {
            this.observer = new IntersectionObserver(this.handleIntersection.bind(this));

            // Observe the target element
            this.observer.observe(this.footer);
        }
    }

    handleIntersection (entries) {
        const entry = entries[0];
        this.pageContent = document.querySelector('.l-page-content');
        this.navParents = this.mainHeader.querySelectorAll('.c-mainnav__arrow');

        if (entry.isIntersecting) {
            this.pageContent.classList.add('l-page-content--extra-spacing-top');
            this.mainHeader.classList.add('is-sticky');

            this.navParents.forEach(element => {
                element.classList.add('u-hide');
            });
        } else {
            this.mainHeader.classList.remove('is-sticky');
            this.pageContent.classList.remove('l-page-content--extra-spacing-top');

            this.navParents.forEach(element => {
                element.classList.remove('u-hide');
            });
        }
    }
}
