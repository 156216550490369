/**
 * Gallery Class
 *
 * @version 1.0.0
 * @copyright 2022 SEDA.digital GmbH & Co. KG
 *
 * Inspiration for handling scroll events: https://www.javascripttutorial.net/javascript-dom/javascript-scroll-events/
 */

'use strict';

import ClassLogger from 'ClassLogger';

class Gallery {
    getClassName () { return 'Gallery'; }
    constructor (AntenneCommonServices, Gallery) {
        const self = this;
        self.logger = ClassLogger(self, true); // set second parameter to false to disable logging
        self.AntenneCommonServices = AntenneCommonServices;
        self.Gallery = Gallery;
        self.init();
    }

    init () {
        const self = this;
        const galleries = document.querySelectorAll('[data-gallery]');

        // Gallery was assigned through the constructor
        if (self.Gallery) {
            self.logger.log('Specific gallery triggered');
            self.scrollHandler(self.Gallery);
            self.buttonHandler(self.Gallery);

            return;
        }

        galleries.forEach(gallery => {
            // Only trigger if we don't have a modal. In the case of a modal, the modal will trigger the gallery.
            if (self.checkIfInModal(gallery) === false) {
                // TODO refactor all galleries to render dot nav via js
                if (gallery.classList.contains('c-gallery--references')) {
                    self.renderDotnavigation(gallery);
                }

                self.scrollHandler(gallery);
                self.buttonHandler(gallery);
            }
        });
    }

    /**
     * @param {Element} gallery
     * @returns {Boolean}
     */
    checkIfInModal (gallery) {
        /* If gallery is in modal, do not register it.
         * It needs to be registered within the modal.
         * See the Modal.js for more info.
        */
        const modal = gallery.parentNode;

        if (modal.classList.contains('c-modal__content')) {
            return true;
        }

        return false;
    }

    /**
     * Added when refactoring references slider. Dots were already a part
     * of the original script and are generated via PHP in the first gallery,
     * but for the references, we are rendering them via js.
     *  @param {Element} gallery
     */
    renderDotnavigation (gallery) {
        const self = this;
        const dotWrapper = gallery.querySelector('.c-gallery__nav-list');
        if (!dotWrapper) {
            this.logger.warn('No slider dots found', { gallery });
            return;
        }

        const sliderItems = gallery.querySelectorAll('.c-gallery__slide');

        if (sliderItems.length > 1 && dotWrapper !== null) {
            sliderItems.forEach((slide, index) => {
                index = index + 1;
                let activeClass = '';
                if (index === 1) {
                    activeClass = 'is-active';
                }

                const dotNode = self.AntenneCommonServices.markupToElement(`
                    <li class="c-gallery__nav-item">
                        <span data-dotlink class="${activeClass}" data-nav-idx="${index}">zu Slide ${index}</span>
                    </li>
                `);

                dotWrapper.append(dotNode);
            });
        }
    }

    /**
     *
     * @param {Element} gallery
     */
    buttonHandler (gallery) {
        const slideWidth = gallery.offsetWidth;
        const track = gallery.querySelector('.c-gallery__viewport');

        const nextBtn = gallery.querySelector('[data-gallery-next]');
        const prevBtn = gallery.querySelector('[data-gallery-prev]');

        nextBtn.addEventListener('click', (e) => {
            track.scrollLeft += slideWidth;
        });

        prevBtn.addEventListener('click', (e) => {
            track.scrollLeft -= slideWidth;
        });
    }

    /**
     *
     * @param {Number} idx
     * @param {Element} gallery
     */
    assignDotActiveClass (idx, gallery) {
        const self = this;

        const item = gallery.querySelector(`[data-nav-idx="${idx}"]`);
        if (item) {
            self.removeDotActiveClasses(gallery);
            item.classList.add('is-active');
        }
    }

    /**
     *
     * @param {Element} gallery
     */
    removeDotActiveClasses (gallery) {
        const dotItems = gallery.querySelectorAll('[data-dotlink]');

        dotItems.forEach(item => {
            item.classList.remove('is-active');
        });
    }

    /**
     *
     * @param {Element} gallery
     */
    scrollHandler (gallery) {
        const self = this;
        const galleryTrack = gallery.querySelector('.c-gallery__viewport');

        if (!galleryTrack) {
            self.logger.log('No slides found for', gallery);
            return;
        }

        // Check for options within our data attribute
        let options = {};
        const galleryOptions = gallery.dataset.gallery;

        if (galleryOptions) {
            options = JSON.parse(galleryOptions);

            if (options.adaptiveHeight) {
                self.animateHeight(galleryTrack.firstElementChild, gallery);
            }
        }

        const galleryItems = galleryTrack.querySelectorAll('.c-gallery__slide');
        galleryTrack.dataset.total = galleryItems.length;

        galleryTrack.addEventListener('scroll', (e) => {
            self.AntenneCommonServices.debounce(self.checkSlidePosition(gallery, galleryTrack, options), 500);
        }, { passive: true });

        const observer = new IntersectionObserver((entries) => {
            self.handleSlideIntersect(gallery, galleryTrack, options, entries);
        }, {
            root: gallery,
            rootMargin: '0px',
            threshold: 0.15,
        });

        galleryItems.forEach((slide) => {
            observer.observe(slide);
        });
    }

    /**
     *
     * @param {Element} gallery
     * @param {Element} galleryTrack
     * @param {Object} options
     * @param {Array} entries
     */
    handleSlideIntersect (gallery, galleryTrack, options, entries) {
        const self = this;
        const intersectingTargets = [];

        entries.forEach((entry) => {
            if (entry.isIntersecting === true) {
                intersectingTargets.push(entry.target);
            } else {
                entry.target.classList.remove('is-active');
            }
        });

        intersectingTargets.forEach(entryTarget => {
            entryTarget.classList.add('is-active');
        });

        self.checkSlidePosition(gallery, galleryTrack, options);
    }

    /**
     *
     * @param {Element} gallery
     * @param {Element} galleryTrack
     * @param {Object} options
     */
    checkSlidePosition (gallery, galleryTrack, options) {
        const self = this;

        let adaptiveHeight = false;
        if (options) {
            adaptiveHeight = options.adaptiveHeight ? options.adaptiveHeight : false;
        }

        const hasNextSlide = self.hasNextSlide(galleryTrack);
        const hasPrevious = galleryTrack.scrollLeft > 0;

        // Those classes are purely used for the left/right GRADIENTS to dissapear asap
        if (hasPrevious === false) {
            gallery.classList.add('has-no-prev');
        } else {
            gallery.classList.remove('has-no-prev');
        }

        if (hasNextSlide === false) {
            gallery.classList.add('has-no-next');
        } else {
            gallery.classList.remove('has-no-next');
        }

        // Check if IDX changed
        let newActiveSlideIdx = 1;
        const activeItems = galleryTrack.querySelectorAll('.c-gallery__slide.is-active');
        if (activeItems.length > 0) {
            newActiveSlideIdx = parseInt(activeItems[0].dataset.slideIdx);
            if (hasNextSlide === false) {
                // if we have NO next slide, we must show the last idx as count
                newActiveSlideIdx = parseInt(activeItems[activeItems.length - 1].dataset.slideIdx);
            }
        }

        // if IDX changed...
        if (parseInt(galleryTrack.dataset.currentslide) !== newActiveSlideIdx) {
            self.handleChangedIdx(gallery, galleryTrack, newActiveSlideIdx);
        }

        self.removeDotActiveClasses(gallery);
        self.assignDotActiveClass(newActiveSlideIdx, gallery);
        if (adaptiveHeight) {
            self.animateHeight(activeItems[0], gallery);
        }
    }

    /**
     *
     * @param {Element} slide
     * @param {Element} gallery
     */
    animateHeight (slide, gallery) {
        const self = this;
        self.logger.log('animating Height', slide.scrollHeight);
        const slideHeight = slide.firstElementChild.scrollHeight + 'px';
        gallery.style.height = slideHeight;
    }

    /**
     *
     * @param {Element} gallery
     * @param {Element} galleryTrack
     * @param {Number} newActiveSlideIdx
     */
    handleChangedIdx (gallery, galleryTrack, newActiveSlideIdx) {
        galleryTrack.dataset.currentslide = newActiveSlideIdx;

        // Update disabled state of control next/prev buttons
        const controlPrev = gallery.querySelector('.c-gallery__prev');
        const controlNext = gallery.querySelector('.c-gallery__next');
        if (controlPrev && controlNext) {
            if (newActiveSlideIdx === parseInt(galleryTrack.dataset.total)) {
                controlNext.classList.add('is-disabled');
            } else {
                controlNext.classList.remove('is-disabled');
            }

            if (newActiveSlideIdx === 1 || gallery.classList.contains('has-no-prev')) {
                controlPrev.classList.add('is-disabled');
            } else {
                controlPrev.classList.remove('is-disabled');
            }
        }
    }

    /**
     *
     * @param {Element} galleryTrack
     * @returns {Boolean}
     */
    hasNextSlide (galleryTrack) {
        const hasNextSlide = galleryTrack.offsetWidth + Math.ceil(galleryTrack.scrollLeft) + 1 < galleryTrack.scrollWidth;
        return hasNextSlide;
    }
}

export default Gallery;
