'use strict';

import CmpVendorScriptLoader from './components/CmpVendorScriptLoader';
import AntenneCommonServices from './components/CommonServices';
import Offcanvas from './components/Offcanvas';
import Modal from './components/Modal';
import Gallery from './components/Gallery';
import Slider from './components/Slider';
import Tabs from './components/Tabs';
import NewsFilter from './components/Newsfilter';
import SubNav from './components/Subnav';
import Embed from './components/Embed';
import StickyHeader from './components/stickyHeader';

// Init Common Services
const common = new AntenneCommonServices();
// Expose global variable for common services
window.antenneServices = common;

// Dispatch a global event when the main js has been loaded
window.dispatchEvent(new CustomEvent('abygroup.jsloaded'));

// Load Scripts based on CMP-Consents
new CmpVendorScriptLoader(common);

// Offcanvas
new Offcanvas(common);

common.isDomReady().then(() => {
    new Modal();
    new Gallery(common);
    new Slider();
    new Tabs();
    new NewsFilter(common);
    new SubNav(common);
    new Embed(common);
    new StickyHeader();
});

// dispatched sync "finish" event
window.dispatchEvent(new CustomEvent('abygroup.jsexecuted'));

document.documentElement.classList.remove('u-no-js');
document.documentElement.classList.add('u-supports-js');
