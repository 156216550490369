/**
 * CmpVendorScriptLoader
 * This is used to load scripts conditionally based on a CMP consent.
 * Example: `<script type="text/plain" data-cmpvendorid="755"></script>`
 *
 * @version 1.0.0
 * @copyright 2021 SEDA.digital GmbH & Co. KG
 */

'use strict';

import ClassLogger from 'ClassLogger';

class CmpVendorScriptLoader {
    getClassName () { return 'CmpVendorScriptLoader'; }
    constructor (AntenneCommonServices) {
        const self = this;
        self.logger = ClassLogger(self, true); // set second parameter to false to disable logging
        self.AntenneCommonServices = AntenneCommonServices;

        self.AntenneCommonServices.waitForExplicitConsent().then(async () => {
            await self.AntenneCommonServices.isDomReady();
            const vendorConsents = await self.AntenneCommonServices.getTcfConsents(self.getRequiredVendorIds());
            self.enableVendorScripts(vendorConsents);
        });
    }

    getNodes () {
        return document.querySelectorAll('[data-cmpvendorid]');
    }

    getRequiredVendorIds () {
        const self = this;
        const ids = [];
        self.getNodes().forEach(function (node) {
            ids.push(self.getVendorIdFromNode(node));
        });
        self.logger.log('Found vendor ids', ids);
        return ids;
    }

    getVendorIdFromNode (node) {
        return parseInt(node.getAttribute('data-cmpvendorid'));
    }

    enableVendorScripts (vendorConsents) {
        const self = this;
        self.logger.log('Enabling vendor scripts', vendorConsents);
        self.getNodes().forEach(function (node) {
            const vendorId = self.getVendorIdFromNode(node);

            if (typeof vendorConsents[vendorId] === 'undefined') {
                self.logger.warn('TCF: No consent information for IAB vendor ID', vendorId);
                return;
            }

            if (vendorConsents[vendorId] === true) {
                self.logger.log('TCF: Enabling vendor ' + vendorId);
                const clone = document.createElement('script');
                clone.innerHTML = node.innerHTML;
                clone.type = 'text/javascript';
                for (const key in node) {
                    if (key.indexOf('on') === 0) {
                        clone[key] = node[key];
                    }
                }
                document.head.appendChild(clone);
            } else {
                self.logger.log('TCF: No consent vor vendor ' + vendorId);
            }
        });
    }
}

export default CmpVendorScriptLoader;
