/**
 * Dialog Class
 *
 * @version 1.0.0
 * @copyright 2022 SEDA.digital GmbH & Co. KG
 */

'use strict';

import ClassLogger from 'ClassLogger';
import A11yDialog from 'a11y-dialog';
import Gallery from './Gallery';

class Modal {
    getClassName () { return 'Modal'; }
    constructor () {
        const self = this;
        self.logger = ClassLogger(self, true); // set second parameter to false to disable logging
        self.init();
    }

    init () {
        const self = this;
        const modals = document.querySelectorAll('.c-modal');

        modals.forEach(modal => {
            const dialog = new A11yDialog(modal);
            dialog.on('show', function (el) {
                el.classList.add('is-visible');
                el.focus();
                self.checkForGallery(el);
            }).on('hide', function (el) {
                el.classList.remove('is-visible');
            });
            if (modal.getAttribute('data-autoopen') === 'true') {
                self.logger.log('Automatically opening modal', modal.id);
                dialog.show();
            }
        });
    }

    checkForGallery (el) {
        const modalGallery = el.querySelector('[data-gallery]');

        if (modalGallery !== null) {
            new Gallery(window.antenneServices, modalGallery);
        }
    }
}

export default Modal;
