/**
 * AntenneCommonServices.js
 *
 * @version 1
 * @copyright 2021 SEDA.digital GmbH & Co. KG
 */

'use strict';

import ClassLogger from 'ClassLogger';

class AntenneCommonServices {
    getClassName () { return 'AntenneCommonServices'; }
    constructor () {
        const self = this;
        self.logger = ClassLogger(self, true);
        self.components = {};
    }

    async waitForCmpIsAvailable ({ timeoutInSeconds } = {}) {
        const promise = new Promise((resolve, reject) => {
            if (window.UC_UI && window.UC_UI.isInitialized()) {
                resolve();
            } else {
                window.addEventListener('UC_UI_INITIALIZED', () => {
                    resolve();
                });
            }
        });

        return timeoutInSeconds > 0
            ? Promise.race([promise, this.timeoutAfterSeconds(timeoutInSeconds)])
            : promise;
    }

    /**
     * Get consent status for IAB Vendor
     * @param {string[]} vendorIds Array of Usercentrics Vendor TemplateIds
     * @returns {Promise<UsercentricsService[]>} array of UC service objects incl. consent status
     */
    async getConsents (vendorTemplateIds = []) {
        if (!Array.isArray(vendorTemplateIds)) {
            throw new TypeError('vendorTemplateIds must be an array or undefined');
        }
        await this.waitForCmpIsAvailable();
        let consents = window.UC_UI.getServicesBaseInfo();
        if (vendorTemplateIds.length > 0) {
            consents = consents.filter(service => vendorTemplateIds.includes(service.id));
        }
        return consents;
    }

    async waitForExplicitConsent () {
        const self = this;
        return new Promise((resolve, reject) => {
            // First strategy: Waiting for the global CMP event
            const handler = e => {
                // self.logger.log('CMP CHANGE', e.detail);
                if (e.detail.type === 'explicit') {
                    window.removeEventListener('QuantyooCmpStatusChange', handler);
                    // self.logger.log('Explicit consent status now available (via event)');
                    resolve();
                }
            };
            window.addEventListener('QuantyooCmpStatusChange', handler);

            // Second strategy: Get Services and check for explicit data
            self.waitForCmpIsAvailable().then(() => {
                UC_UI.getServicesBaseInfo().some(service => {
                    let currentConsent = service.consent.history[0];
                    if (service.consent.history.length > 1) {
                        currentConsent = service.consent.history[service.consent.history.length - 1];
                    }
                    if (currentConsent.type === 'explicit') {
                        // self.logger.log('Found explicit consent in services');
                        resolve();
                        return true; // "break" loop on first explicit consent
                    }

                    return false;
                });
            });
        });
    }

    onConsentChange (callback) {
        window.addEventListener('QuantyooCmpStatusChange', callback);
    }

    /**
     * Get consent status for IAB Vendor
     * @param {int[]} vendorIds Array of TCF IAB Vendor IDs
     * @returns {Promise<boolean>} consent status
     */
    async getTcfConsents (vendorIds) {
        const self = this;
        if (!Array.isArray(vendorIds)) {
            throw new TypeError('vendorIds must be an array of integers.');
        }
        return new Promise((resolve, reject) => {
            if (typeof __tcfapi !== 'function') {
                reject(new Error('__tcfapi not a function'));
                return;
            }
            __tcfapi('getTCData', 2, (tcData, success) => {
                if (success !== true || typeof tcData !== 'object') {
                    self.logger.error('getTCData not successfull', success, tcData);
                    reject(new Error('getTCData not successful'));
                }
                resolve(tcData.vendor.consents);
            }, vendorIds);
        });
    }

    async areAllConsentsAccepted () {
        if (!UC_UI) {
            throw new Error('CMP not available');
        }
        return UC_UI.areAllConsentsAccepted();
    }

    /**
     * Returns a promise that resolves once the DOM is ready
     * this is useful for async loaded scripts
     * @returns Promise
     */
    async isDomReady () {
        return new Promise((resolve, reject) => {
            if (document.readyState === 'interactive' || document.readyState === 'complete') {
                resolve();
                return;
            }

            document.addEventListener('DOMContentLoaded', () => {
                resolve();
            });
        });
    }

    debounce (func, wait = 300, immediate = false) {
        let timeout;
        return function () {
            const context = this;
            const args = arguments;
            const later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    isInViewport (element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    /**
     * Returns a promise that rejects after the given `timeoutInSeconds`.
     *
     * @param {int} timeoutInSeconds  the number of seconds before timing out
     *
     * @returns {Promise<void>}
     */
    timeoutAfterSeconds (timeoutInSeconds) {
        return new Promise((_resolve, reject) => {
            const id = setTimeout(() => {
                clearTimeout(id);
                reject(new Error(`timeout after ${timeoutInSeconds} seconds`));
            }, timeoutInSeconds * 1000);
        });
    }

    markupToElement (markup) {
        const template = document.createElement('template');
        template.innerHTML = markup.trim();
        return template.content.firstChild;
    }
}

export default AntenneCommonServices;
