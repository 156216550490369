'use strict';

import ClassLogger from 'ClassLogger';

class NewsFilter {
    getClassName () { return 'NewsFilter'; }
    constructor (AntenneCommonServices) {
        const self = this;
        self.AntenneCommonServices = AntenneCommonServices;
        self.logger = ClassLogger(self, true); // set second parameter to false to disable logging
        self.offset = 0;
        self.limit = 9;
        self.categories = [];
        self.clearResults = true;
        self.loadMoreFound = true;

        self.component = document.querySelector('[data-newsfilter]');
        if (self.component) {
            self.init();
        }
    }

    init () {
        const self = this;

        self.logger.log('init');
        self.assignDefaultConfigs();
        self.testForLoadMoreButton();
        self.addEventListeners();
        self.filter(true);
    }

    assignDefaultConfigs () {
        const self = this;

        if (self.component.dataset.newsfilterLimit) {
            self.limit = self.component.dataset.newsfilterLimit;
            self.logger.log('Changing filter limit to: ' + self.limit);
        }

        // Set our categories for our search
        if (self.component.dataset.newsfilter !== 'Alle') {
            self.categories.push(self.component.dataset.newsfilter);
        }
    }

    testForLoadMoreButton () {
        const self = this;
        const loadMoreButton = self.component.querySelector('[data-newsfilter-loadmore]');
        if (loadMoreButton === null) {
            self.logger.log('Do not allow loading of new articles');
            self.loadMoreFound = false;
        }
    }

    filter (firstload = false) {
        const self = this;

        self.logger.log('Starting filter');

        const loader = self.component.querySelector('.o-spinner');

        if (firstload) {
            // On first load, check if component is in the viewport. If it is, show loader.
            // Otherwise, don't show the loader because it covers up whole page.
            if (self.AntenneCommonServices.isInViewport(self.component)) {
                loader.classList.remove('u-hide');
            }
        } else {
            loader.classList.remove('u-hide');
        }

        // Visual effect on results
        const results = self.component.querySelector('[data-newsfilter-results]');
        results.style.setProperty('opacity', '.5');

        self.filterAbortController = new AbortController();
        self.filterAbortController.signal.addEventListener('abort', e => {
            self.logger.log('request was aborted');
            if (window.console && window.console.time) {
                window.console.timeEnd('filter_request');
            }
        });

        if (window.console && window.console.time) {
            window.console.time('filter_request');
        }

        const criteria = {
            offset: self.offset,
            limit: self.limit,
            categories: self.categories,
        };

        self.logger.log('Filtering categories', criteria);

        const ajax = fetch('/ajax.json?' + self.toQueryString(criteria), {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
            credentials: 'same-origin',
            referrerPolicy: 'same-origin',
            signal: self.filterAbortController.signal,
        })
            .then(response => response.json())
            .then(data => {
                if (data.success !== true) {
                    self.logger.error('Non-successful response', data);
                    throw new Error('Fehlerhafte Antwort vom Server.');
                }

                self.renderResults(data);
            })
            .catch(error => {
                if (error.name === 'AbortError' || error.message === 'Failed to fetch') {
                    self.logger.log('Search aborted');
                } else {
                    self.logger.error('Fetch error', error);
                    if (self.filterAbortController.signal.aborted !== true) {
                        alert('Es trat ein Fehler auf');
                    }
                }
            })
            .finally(() => {
                // Reset our visual state
                loader.classList.add('u-hide');
                results.style.setProperty('opacity', '1');
                if (window.console && window.console.time) {
                    window.console.timeEnd('filter_request');
                }
            });

        return ajax;
    }

    renderResults (data) {
        const self = this;
        const noresults = self.component.querySelector('[data-newsfilter-noresults]');
        const results = self.component.querySelector('[data-newsfilter-results]');
        const loadMoreButton = self.component.querySelector('[data-newsfilter-loadmore]');

        results.style.minHeight = 'inherit';

        if (data.results === '') {
            noresults.classList.remove('u-hide');
            noresults.setAttribute('aria-hidden', false);
            results.innerHTML = '';
            if (self.loadMoreFound) { loadMoreButton.classList.add('u-hide'); }
            return;
        }

        noresults.classList.add('u-hide');
        noresults.setAttribute('aria-hidden', true);

        if (self.clearResults === true) {
            results.innerHTML = '';
        }

        results.insertAdjacentHTML('beforeend', data.results);

        // Handle more button visibility
        if (self.loadMoreFound) {
            const articles = self.component.querySelectorAll('.c-card');
            if (data.count > articles.length) {
                loadMoreButton.classList.remove('u-hide');
            } else {
                loadMoreButton.classList.add('u-hide');
            }
        }
    }

    addEventListeners () {
        const self = this;
        const checkboxes = self.component.querySelectorAll('input[type=checkbox]');
        checkboxes.forEach((filter) => {
            filter.addEventListener('click', e => {
                self.clearResults = true;
                self.offset = 0;
                self.getSelectedCategories();
            });
        });

        // We have a use case where we do not wish to use a load more button.
        if (self.loadMoreFound) {
            const loadMoreButton = self.component.querySelector('[data-newsfilter-loadmore]');
            loadMoreButton.addEventListener('click', e => {
                loadMoreButton.blur();
                self.loadMore();
            });
        }

        // Handle toggling news filters
        const toggleButton = self.component.querySelector('[data-newsfilter-togglebutton]');
        const filters = self.component.querySelector('.c-newsfilter__filters');

        if (toggleButton) {
            toggleButton.addEventListener('click', e => {
                if (filters.classList.contains('u-hide-small')) {
                    filters.classList.remove('u-hide-small');
                } else {
                    filters.classList.add('u-hide-small');
                }
            });
        }
    }

    loadMore () {
        const self = this;
        self.logger.log('Clicked Load More Button');
        const articles = self.component.querySelectorAll('.c-card');
        self.offset = articles.length;
        self.clearResults = false;

        self.filter();
    }

    getSelectedCategories () {
        const self = this;
        self.categories = [];
        const checkedboxes = self.component.querySelectorAll('input[type=checkbox]:checked');
        checkedboxes.forEach((checkbox) => {
            self.categories.push(checkbox.value);
        });

        self.filter();
    }

    toQueryString (object) {
        return Object.keys(object)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`)
            .join('&');
    }
}

export default NewsFilter;
