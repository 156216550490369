'use strict';

import ClassLogger from 'ClassLogger';

class Tabs {
    getClassName () { return 'Tabs'; }
    constructor () {
        const self = this;
        self.logger = ClassLogger(self, true); // set second parameter to false to disable logging

        self.tabcomponents = document.querySelectorAll('.c-tabs');
        if (self.tabcomponents) {
            self.tabcomponents.forEach((tabcomponent) => {
                self.init(tabcomponent);
            });
        } else {
            self.logger.log('No tabcomponents found');
        }
    }

    init (tabcomponent) {
        const self = this;
        self.tabs = tabcomponent.querySelectorAll('[aria-selected]');
        self.tabpanels = tabcomponent.querySelectorAll('[aria-expanded]');

        self.tabs.forEach((tab) => {
            tab.addEventListener('click', () => {
                self.reset();
                const ariaControls = tab.getAttribute('aria-controls');
                tab.setAttribute('aria-selected', true);
                document.getElementById(ariaControls).setAttribute('aria-expanded', true);
            });
        });
    }

    reset () {
        const self = this;
        self.tabs.forEach((tab) => {
            tab.setAttribute('aria-selected', false);
        });

        self.tabpanels.forEach((tabpanel) => {
            tabpanel.setAttribute('aria-expanded', false);
        });
    }
}

export default Tabs;
