'use strict';

import ClassLogger from 'ClassLogger';

class Embed {
    getClassName () {
        return 'Embed';
    }

    /**
     * Create a new instance.
     * @param {AntenneCommonServices} AntenneCommonServices
     */
    constructor (AntenneCommonServices) {
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging

        this.AntenneCommonServices = AntenneCommonServices;

        this.AntenneCommonServices.waitForExplicitConsent({
            timeoutInSeconds: 5,
        }).catch(() => {
            this.logger.warn('Did not get an explicit consent.');
        }).finally(() => {
            window.document.documentElement.classList.add('embeds-consent-available');
            this.findAndActivateEmbeds();

            // once we got initial explicit consent, we monitor for changes to activate more embeds
            this.AntenneCommonServices.onConsentChange(() => {
                this.findAndActivateEmbeds();
            });
        });
    }

    async findAndActivateEmbeds () {
        const embeds = document.querySelectorAll('.c-embed[data-uctemplateid][data-vendorname]:not(.is-loaded)');
        if (embeds.length === 0) {
            this.logger.log('found no embeds');
            return;
        }

        this.restartConsentEmbeddings();

        embeds.forEach((embedElement) => {
            try {
                this.checkConsentAndActivate(embedElement);
            } catch (error) {
                this.logger.log(error);
            }
        });
    }

    /**
     * If Usercentrics is available, programmatically rerender the embeddings content.
     * Does nothing if Usercentrics isn’t available or not yet initialized.
     */
    async restartConsentEmbeddings () {
        try {
            await this.AntenneCommonServices.waitForCmpIsAvailable();
            await window.UC_UI.restartEmbeddings();
        } catch (error) {
            this.logger.error('Failed to restart Usercentrics embeddings', error);
            // do nothing
        }
    }

    async checkConsentAndActivate (embedElement) {
        const vendorName = embedElement.dataset.vendorname;
        const vendorTemplateId = embedElement.dataset.uctemplateid;

        // Add click listener
        const optinButton = embedElement.querySelector('.c-embed__optinbutton:not(.has-clickhandler');
        if (optinButton) {
            optinButton.classList.add('has-clickhandler');
            optinButton.addEventListener('click', async (e) => {
                this.logger.log('Accept button clicked', embedElement);
                optinButton.classList.add('disabled');
                this.activateEmbed(embedElement);
            }, { once: true });
        }

        const services = await this.AntenneCommonServices.getConsents([vendorTemplateId]);
        if (services.length !== 1) {
            throw new Error('Unexpected number of services returned');
        }
        const service = services[0];

        if (service.consent.status === true) {
            this.activateEmbed(embedElement);
        } else {
            this.logger.log('NO consent for ' + vendorName, { service });
        }
    }

    async activateEmbed (embedElement) {
        this.logger.log('Activating embed', embedElement);
        if (embedElement.classList.contains('is-loaded')) {
            this.logger.warn('Embed already loaded');
            return;
        }
        embedElement.classList.add('is-loaded');

        const xmp = embedElement.querySelector('xmp');
        const fillArea = embedElement.querySelector('.c-embed__live');
        const previewArea = embedElement.querySelector('.c-embed__preview');

        const range = document.createRange();
        range.setStart(embedElement, 0);
        fillArea.appendChild(range.createContextualFragment(xmp.textContent));
        previewArea.classList.add('hide');
        previewArea.setAttribute('aria-hidden', 'true');

        embedElement.classList.add('is-initialized');
    }
}

export default Embed;
