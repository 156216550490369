'use strict';

import ClassLogger from 'ClassLogger';
/**
 * This script centers the subnavigation menu items according to middle point of the main menu item.
 */

class SubNav {
    getClassName () { return 'SubNav'; }
    constructor (AntenneCommonServices) {
        const self = this;
        self.logger = ClassLogger(self, true); // set second parameter to false to disable logging
        self.AntenneCommonServices = AntenneCommonServices;

        const subnav = document.querySelector('.c-secondary-nav');
        if (!subnav) {
            return;
        }

        self.init(subnav);

        window.addEventListener('resize', () => {
            self.AntenneCommonServices.debounce(self.init(subnav));
        });

        /**
         * If the user's entry point was at a section of the webpage
         * where the navigation was not being shown, sizing was not able to
         * be read out of the DOM, therefore, we also watch on the scroll addEventListener
         * to trigger this script.
         */
        window.addEventListener('scroll', () => {
            self.AntenneCommonServices.debounce(self.init(subnav));
        });
    }

    init (subnav) {
        const largeMediaQuery = window.matchMedia('(min-width: 1020px)');
        if (!largeMediaQuery.matches) {
            return;
        }

        const self = this;
        const mainNavItems = document.querySelectorAll('.c-mainnav ul li');

        let selectedItem = '';
        mainNavItems.forEach(item => {
            if (item.classList.contains('is-active')) {
                selectedItem = item;
            }
        });

        const itemLink = selectedItem.querySelector('a');
        const subNavUl = subnav.querySelector('ul');
        self.centerElementUnderneath(subNavUl, itemLink);
    }

    /**
     * Get center point of width of item and center the subnav item underneath it.
     * NOTE - when Google Devtools are opened, it does not calculate correctly on page load
     */
    centerElementUnderneath (targetElement, referenceElement) {
        const self = this;

        if (!targetElement || !referenceElement) {
            self.logger.log('One or both of the elements are not found.');
            return;
        }

        // Get the dimensions of the reference element
        const referenceRect = referenceElement.getBoundingClientRect();

        // Calculate the left position to center the target element
        const targetLeft = referenceRect.left + (referenceRect.width - targetElement.offsetWidth) / 2;

        // Set the position of the target element
        targetElement.style.left = targetLeft + 'px';

        // Bring our target into Viewport if it is found outside
        if (!self.AntenneCommonServices.isInViewport(targetElement)) {
            targetElement.classList.add('bring-in-viewport');
        } else {
            targetElement.classList.remove('bring-in-viewport');
        }
    }
}

export default SubNav;
