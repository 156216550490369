/**
 * Slider Class
 *
 * @version 1.0.0
 * @copyright 2022 SEDA.digital GmbH & Co. KG
 * Used for the History component
 * https://nickpiscitelli.github.io/Glider.js/
 */

'use strict';

import ClassLogger from 'ClassLogger';
import Glider from 'glider-js';

class Slider {
    getClassName () { return 'Slider'; }
    constructor () {
        const self = this;
        self.logger = ClassLogger(self, true); // set second parameter to false to disable logging
        self.init();
    }

    init () {
        const sliders = document.querySelectorAll('[data-slider]');

        sliders.forEach(slider => {
            const options = this.setOptions();
            new Glider(slider, options);
        });
    }

    setOptions () {
        return {
            slidesToShow: 1.5,
            slidesToScroll: 1,
            duration: 0.25,
            draggable: false,
            resizeLock: true,
            arrows: {
                prev: '.glider-prev',
                next: '.glider-next',
            },
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 'auto',
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 1020,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    },
                },
            ],
        };
    }
}

export default Slider;
